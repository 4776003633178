import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/layout";
import { StickyContainer } from "react-sticky";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import { ReactComponent as FacebookIcon } from "../../../images/blogs/Facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../images/blogs/Twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../../images/blogs/LinkedIn.svg";
import { ReactComponent as LinkIcon } from "../../../images/blogs/Link.svg";

import "./BlogPost.scss";
import { convertStringToURL } from "../../../utils/utils";
import { Helmet } from "react-helmet";
import ColorfulCTAComponent from "../../Common/ColorfulCTAComponent/ColorfulCTAComponent";
var parse = require("html-react-parser");

function BlogPost(props) {
  const { blog } = props.pageContext;
  const url = convertStringToURL(blog)
  const intl = useIntl();
  const blogCont = useRef(null);
  const copyLinkToast = useRef(null);
  const [htmlContent, setHtmlContent] = useState("")

  useEffect(() => {
    setHtmlContent(blog.content)
  }, [blog])


  const copyLinkButtonHandler = () => {
    if (copyLinkToast && copyLinkToast.current) {
      gsap.to(copyLinkToast.current, { transform: "translateX(-40px)", opacity: 0 });
      gsap.to(copyLinkToast.current, {
        transform: "translateX(0px)",
        opacity: 1,
        duration: 1,
        ease: "power3",
        onComplete: function(){
          gsap.to(copyLinkToast.current, {
            opacity: 0,
            delay:1,
            duration: 1,
            ease: "power3",
          })
        }
      }
      )
    }
    navigator.clipboard.writeText(process.env.HOST_URL + "/blog/" + url);
  }


  return (
    <StickyContainer>
      <Layout>
        <Helmet>
          <title>{blog.title}</title>
          <meta name="title" content={blog.title} />
          <meta name="description" content="Start to Shop, Pay, and Earn the Clever way today!" />
          <meta name="image" content={blog.thumbnail} />
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content="Start to Shop, Pay, and Earn the Clever way today!" />
          <meta property="og:image" content={blog.thumbnail} />
          <meta property="og:url" content={process.env.HOST_URL + "/blog/" + url} />
          <meta property="og:type" content="article" />
          <meta name="twitter:title" content={blog.title} />
          <meta name="twitter:image" content={blog.thumbnail} />
          <meta name="twitter:description" content="Start to Shop, Pay, and Earn the Clever way today!" />
          <meta name="keywords" content={blog.categories} />
          <meta name="twitter:creator" content={"@hello_clever"} />
        </Helmet>
        <div className="blogPost">
          <div className="blogPost_heroImg" style={{ backgroundImage: 'url(' + blog.thumbnail + ')' }}></div>
          <div className="blogPost_cont">
            <div className="blogPost_cont_share">
              <span className="blogPost_cont_share_label">Share</span>
              <div className="blogPost_cont_share_iconCont">
                <FacebookShareButton
                  url={process.env.HOST_URL + "/blog/" + url}
                  quote={blog.title}
                  hashtag={"#helloclever"}
                  description={blog.title}
                  className="blogPost_cont_share_iconCont_icon">
                  <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton
                  title={blog.title}
                  url={process.env.HOST_URL + "/blog/" + url}
                  quote={blog.title}
                  hashtag={"#helloclever"}
                  description={blog.title}
                  className="blogPost_cont_share_iconCont_icon">
                  <TwitterIcon />
                </TwitterShareButton>
                <LinkedinShareButton
                  title={blog.title}
                  url={process.env.HOST_URL + "/blog/" + url}
                  quote={blog.title}
                  hashtag={"#helloclever"}
                  description={blog.title}
                  className="blogPost_cont_share_iconCont_icon">
                  <LinkedInIcon />
                </LinkedinShareButton >
                <button onClick={copyLinkButtonHandler}>
                  <LinkIcon />
                </button>
                <div ref={copyLinkToast} className="copiedToast">Copied</div>
              </div>
            </div>

            <div className="blogPost_cont_blogCont" ref={blogCont}>
              {parse(htmlContent)}
            </div>
          </div>
          <ColorfulCTAComponent
            title={intl.formatMessage({ id: "BlogPost.Signup.Title" })}
            subtitle={intl.formatMessage({ id: "BlogPost.Signup.Subtitle" })}
            button={intl.formatMessage({ id: "BlogPost.Signup.Button" })}
            link={"https://merchant.helloclever.co/enter-email"}
            isAppDownload={true}
          />
        </div>
      </Layout>
    </StickyContainer>
  );
}

export default injectIntl(BlogPost);
